const externalAuth = {
  namespaced: true,
  state () {
    return {
      userId: null,
      session: null,
      rand: null,
      ymId: null,
      gaId: null,
    }
  },
  mutations: {
    setExternalAuth(state, authData) {
      state.userId = authData.userId;
      state.session = authData.session;
      state.rand = authData.rand;
    },
    setYmId(state, id) {
      state.ymId = id;
    },
    setGaId(state, id) {
      state.gaId = id;
    },
  },
  actions: {
  }
}

export default externalAuth;
