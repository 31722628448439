const communications = {
  namespaced: true,
  state() {

  },
  actions: {
    sendEvent(context, msg) {
      console.log(msg);
      const payload = {
        action: msg.action,
      };
      if (window.WebToNativeBridge && window.WebToNativeBridge.postMessage) {
        window.WebToNativeBridge.postMessage(msg.action);
      } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.WebToNativeBridge) {
        window.webkit.messageHandlers.WebToNativeBridge.postMessage(msg.action);
      } else {
        window.top.postMessage(payload, "*")
      }
    },
    sendLoadedEvent({ dispatch }) {
      dispatch('sendEvent', {action: 'event_loaded'});
    },
    sendCloseEvent({ dispatch, commit }, options = {}) {
      commit('setGlobalLoading', true, {root: true});
      const { route } = options;
      switch (route) {
        case 'osago':
          dispatch('sendEvent', {action: 'action_open_insurance'});
          break;
        case 'fines':
          dispatch('sendEvent', {action: 'action_open_fines'});
          break;
        case 'taxes':
          dispatch('sendEvent', {action: 'action_open_taxes'});
          break;
        case 'main':
          dispatch('sendEvent', {action: 'action_open_main'});
          break;
        default:
          dispatch('sendEvent', {action: 'action_next_screen'});
      }
    }
  },
};

export default communications;