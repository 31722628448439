import osagoApi from "@/services/osagoApi"
import Cookies from "js-cookie";
const kaskoWhiteLabel = {
  namespaced: true,
  state() {
    return {
      authHash: null,
      applicationId: null,
      hasUnpaidKaskoPolicyForm: false,
    }
  },
  getters: {
    authHash(state) {
      return state.authHash;
    },
    applicationId(state) {
      return state.applicationId;
    },
    hasUnpaidKaskoPolicyForm(state) {
      return state.hasUnpaidKaskoPolicyForm;
    },
  },

  mutations: {
    saveAuthHash(state, s) {
      state.authHash = s;
    },
    saveApplicationId(state, s) {
      state.applicationId = s;
    },
    saveHasUnpaidKaskoPolicyForm(state, object) {
      if (object) {
        state.hasUnpaidKaskoPolicyForm = !object.paid && !object.policyFormRequestNotExists;
      }
    },
  },

  actions: {
    async getAuthHash({commit}) {
      if (isNaN(parseInt(Cookies.get('requestParams.userId')))) {

        return {};
      }

      const response = await osagoApi.post("/kasko-white-label/create-session-hash", {})
      if (osagoApi.isSuccess(response)) {
        commit("saveAuthHash", response.data.hash);
        if (response.data.applicationId) {
          commit("saveApplicationId", response.data.applicationId);
        }
        if (response.data.orderStatus) {
          commit("saveHasUnpaidKaskoPolicyForm", response.data.orderStatus);
        }
      } else {
        throw new Error("Get auth hash failed")
      }

      return response;
    },
  }
}

export default kaskoWhiteLabel;