import * as VueRouter from 'vue-router'
import finesApi from '@/services/finesApi';
import config from '@/config';

import MainLayout from "@/layouts/MainLayout";
import store from '@/store';
import { mapActions, mapGetters } from 'vuex';

const Main = () => import('@/pages/MainPage');

const InsuranceEstate = () => import('@/pages/InsuranceEstate');
const InsuranceHealth = () => import('@/pages/InsuranceHealth');
const InsuranceMortgage = () => import('@/pages/InsuranceMortgage');
const InsuranceTravellers = () => import('@/pages/InsuranceTravellers');


const OsagoRedirect = {
  render: () => {},
  computed: {
    source() {
      return this.$route.query.source;
    },
    path() {
      return this.$route.query.path;
    },
    carId() {
      return this.$route.query.carId;
    },
    offerId() {
      return this.$route.query.offerId;
    },
    vp() {
      return this.$route.query.vp;
    },
    mod() {
      return this.$route.query.mod;
    },
    paymentStatus() {
      return this.$route.query.paymentStatus;
    },
    result() {
      return this.$route.query.result;
    },
  },
  created() {
    const requestParams = finesApi.getRequestParams();
    let url = `${config.OSAGO_WEB_HOST}${this.path ? '/' + encodeURIComponent(this.path) : '/entry'}?skipLanding=1`
    if (!store.state.isWeb) {
      url += '&showAppHeader=1';
    }
    if (requestParams.userId && requestParams.session && requestParams.rand) {
      url += `&userId=${requestParams.userId}&session=${requestParams.session}&rand=${requestParams.rand}`
    }
    if (this.mod) {
      url += `&mod=${encodeURIComponent(this.mod)}`
    }
    if (this.vp) {
      url += `&vp=${encodeURIComponent(this.vp)}`
    } else if (this.carId) {
      url += `&carId=${encodeURIComponent(this.carId)}`
    }
    if (this.offerId) {
      url += `&offerId=${encodeURIComponent(this.offerId)}`
    }
    if (this.paymentStatus) {
      url += `&paymentStatus=${encodeURIComponent(this.paymentStatus)}`
    }
    if (this.result) {
      url += `&result=${encodeURIComponent(this.result)}`
    }
    if (this.source) {
      url += `&source=${encodeURIComponent(this.source)}`
    } else {
      url += "&source=insurance_landing"
    }
    location.replace(url);
  }
};
const KaskoRedirect = {
  render: () => {},
  computed: {
    ...mapGetters({
      authHash: "kaskoWhiteLabel/authHash",
      applicationId: "kaskoWhiteLabel/applicationId",
    }),
    isDocuments() {
      return this.$route.query.documents;
    },
    
  },
  created() {
    if (parseInt(this.isDocuments) === 1 && this.applicationId) {
      location.replace(config.KASKO_WEB_HOST + "/documents?applicationId=" + this.applicationId);
      return;
    }
    location.replace(config.KASKO_WEB_HOST + "?authHash=" + this.authHash);
  },
};

let externalBasePath = "";
const scriptTag = document.getElementById("rf-insurance-script");
if (scriptTag) {
  store.commit("setIsWeb", true);
  store.commit("externalAuth/setExternalAuth",{
    userId: scriptTag.getAttribute('data-user-id'),
    session: scriptTag.getAttribute('data-session'),
    rand: scriptTag.getAttribute('data-rand'),
  });
  store.commit("externalAuth/setYmId", scriptTag.getAttribute('data-ym-id'));
  store.commit("externalAuth/setGaId", scriptTag.getAttribute('data-ga-id'));
  if (scriptTag.getAttribute('data-base-path')) {
    externalBasePath = '/' + scriptTag.getAttribute('data-base-path');
  }
}
const basePath = externalBasePath || "";

const router = VueRouter.createRouter({
  mode: 'history',
  history: VueRouter.createWebHistory(),
  routes: [
    {
      path: basePath + '/',
      component: MainLayout,
      children: [
        { path: '', component: Main, name: 'main' },

        { path: 'estate', component: InsuranceEstate, name: 'estate' },
        { path: 'health', component: InsuranceHealth, name: 'health' },
        { path: 'mortgage', component: InsuranceMortgage, name: 'mortgage' },
        { path: 'travellers', component: InsuranceTravellers, name: 'travellers' },

        { path: 'osago', component: OsagoRedirect, name: 'osago' },
        { path: 'kasko', component: KaskoRedirect, name: 'kasko' },
        // {path: '/osago', component: OsagoFunnel, name: 'osago'},
        // {path: '/kasko', component: KaskoFunnel, name: 'kasko'},
      ]
    },

    {path: '/:pathMatch(.*)', redirect: {name: 'main'}}
  ]
});

const slugsMap = {
  estate: 'Страхование недвижимости',
  health: 'Страхование здоровья',
  mortgage: 'Страхование ипотеки',
  travellers: 'Страхование ВЗР'
}

router.beforeEach(async (to, from, next) => {
  window.scroll({top: 0, behavior: "smooth"})
  let breadcrumbs = [];
  const pathItems = to.path.split("/");
  for(let i = 1; i < pathItems.length; i++) {
    const computedPath = pathItems.slice(0, i+1).join("/");
    if (computedPath === basePath) {
      breadcrumbs.push({
        href: computedPath,
        label: 'Страхование',
      });
    } else if (pathItems[i]) {
      breadcrumbs.push({
        href: computedPath,
        label: slugsMap[pathItems[i]],
      });
    }
  }
  window.setBreadcrumbs && window.setBreadcrumbs(breadcrumbs);

  if (!store.state.isWeb) {
    document.body.setAttribute("data-page", to.name);
  }

  next();
});

export default router;
