import { createApp } from 'vue';

import "vue-loading-overlay/dist/css/index.css";
import "@/assets/styles/style.scss";

import '@/utils/validate';

import App from './App';
import { store } from './store'
import router from './router';

import VueFilters from '@/utils/vue.filters';

const VueApp = window.VueApp = createApp(App);
VueApp.use(store);
VueApp.use(router);
VueApp.mount('#insurance-app');

VueApp.config.globalProperties.$filters = VueFilters;

import '@rosfines/vue-common/common/services/contentChecker';
