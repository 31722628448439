import moment from 'moment';
import { BACK_DATE_FORMAT, FRONT_DATE_FORMAT } from '@/constants/dateFormat';

export const frontDate2BackDate = (frontDate) => {
  let date = moment(frontDate, FRONT_DATE_FORMAT);
  if (date.isValid()) {
    return date.format(BACK_DATE_FORMAT);
  }
  return frontDate;
}

export const backDate2FrontDate = (backDate) => {
  let date = moment(backDate, BACK_DATE_FORMAT);
  if (date.isValid()) {
    return date.format(FRONT_DATE_FORMAT);
  }
  return backDate;
}