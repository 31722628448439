import { createStore } from 'vuex';
import finesApi from '@/services/finesApi';

import communications from "@/store/communications"
import externalAuth from '@/store/externalAuth';
import osago from '@/store/osago';
import kaskoWhiteLabel from '@/store/kaskoWhiteLabel';

export const store = createStore({
  modules: {
    communications,
    externalAuth,
    osago,
    kaskoWhiteLabel,
  },
  state() {
    return {
      globalLoading: true,
      isWeb: false,
    };
  },
  mutations: {
    setGlobalLoading(state, val) {
      state.globalLoading = !!val;
    },
    setIsWeb(state, val) {
      state.isWeb = !!val;
    },
  },
  actions: {
    auth(context, source) {
      if (!finesApi.isAuth()) {
        finesApi.auth(source);
      }
    }
  }
});

export default store;
