export default {
  API_BASE_URL: `${process.env.VUE_APP_API_HOST}`,
  API_BASE_URL_V2: `${process.env.VUE_APP_API_HOST}${process.env.VUE_APP_API_BASE_PATH_V2}`,
  API_BASE_URL_V1: `${process.env.VUE_APP_API_HOST}${process.env.VUE_APP_API_BASE_PATH_V1}`,
  OSAGO_WEB_HOST: `${process.env.VUE_APP_OSAGO_WEB_HOST}`,
  KASKO_WEB_HOST: `${process.env.VUE_APP_KASKO_WEB_HOST}`,
  RECAPTCHA_V3_SITE_KEY: process.env.VUE_APP_RECAPTCHA_V3_SITE_KEY,
  RECAPTCHA_V2_SITE_KEY: process.env.VUE_APP_RECAPTCHA_V2_SITE_KEY,
  STATIC_URL: `${process.env.VUE_APP_STATIC_HOST}/assets/insurance/`,
  YM_SITEKEY: parseInt(`${process.env.VUE_APP_YM_SITEKEY}`, 10),
}
