import axios from 'axios';
import config from "../config";
import Cookies from "js-cookie";

const baseUrl = config.API_BASE_URL_V1;

const axiosApi = axios.create({
  baseURL: config.API_BASE_URL_V1,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  validateStatus: function (status) {
    return [200, 400].indexOf(status) !== -1; // default
  },
});

export default {
  SOURCE_WEB_VERSION: 9,
  SOURCE_LANDING_OSAGO: 19,

  baseUrl: baseUrl,

  post(url, data = {}, params = {}, config = {}) {
    config.params = Object.assign(params, this.getRequestParams());
    return axiosApi.post(url, data, config);
  },

  patch(url, data = {}, params = {}, config = {}) {
    config.params = Object.assign(params, this.getRequestParams());
    return axiosApi.patch(url, data, config);
  },

  get(url, params = {}, config = {}) {
    config.params = Object.assign(params, this.getRequestParams());
    return axiosApi.get(url, config);
  },

  auth() {
    let xhr = new XMLHttpRequest();
    xhr.open('POST', config.API_BASE_URL_V1 + '/auth', false);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");

    xhr.onload = () => {
      console.log(xhr);
      if (xhr.status !== 200) {
        alert(`Ошибка ${xhr.status}: ${xhr.statusText}`);
      } else {
        this.updateRequestParams(JSON.parse(xhr.responseText));
      }
    };
    xhr.onerror = function() {
      alert("Запрос не удался");
    };
    xhr.send();

  },

  isAuth() {
    const params = this.getRequestParams();

    return !!(params.userId && params.rand && params.session);
  },

  getParams(params = {}) {
    return Object.assign(this.getRequestParams(), params);
  },

  getRequestParams() {
    return {
      userId: Cookies.get('requestParams.userId'),
      rand: Cookies.get('requestParams.rand'),
      session: Cookies.get('requestParams.session'),
    }
  },
  updateRequestParams(params) {
    const response = params.response;
    if (!response.userId || !response.rand || !response.session) {
      throw Error('No valid request params');
    }
    let savedUserId = Cookies.get('requestParams.userId');
    Cookies.set('requestParams.userId', response.userId);
    Cookies.set('requestParams.rand', response.rand);
    Cookies.set('requestParams.session', response.session);
    if (savedUserId != response.userId) {
      localStorage.clear();// Просто очищаем все локальные данные при смене пользователя
    }
  },
}
