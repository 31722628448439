<template>
  <div
    class="popup"
    :class="{
      'open': isOpen,
      'popup_gray': isGray,
      'no-title': title === '',
      'short-view': !!shortView,
      'no-animations': !!noAnimations,
    }"
  >
    <div
      class="popup-shadow"
      @click="hide"
    />
    <div class="popup-content">
      <div class="popup-title">
        <span
          class="ico ico_close"
          @click="hide"
        />
        {{ title }}
      </div>
      <div class="popup-body">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import {statService} from '@/utils/statService';

export default {
  name: 'PopupBlock',
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    isGray: {
      type: Boolean,
      required: false,
      default: false,
    },
    shortView: {
      type: Boolean,
      default: false,
    },
    noAnimations: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    show() {
      this.isOpen = true;
      statService.logEvent(`[Действие] Модалка показана ${this.title}`)
    },
    hide() {
      this.isOpen = false;
      statService.logEvent(`[Действие] Модалка скрыта ${this.title}`)
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_vars";

.popup {
  &.open {
    .popup-content {
      left: 0;
      opacity: 1;
    }
    .popup-shadow {
      display: block;
    }
    &.short-view {
      .popup-title {
        display: none;
      }
      .popup-content {
        width: 334px;
        height: auto;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
      }
    }
  }
  &.no-title {
    .popup-title {
      box-shadow: none;
    }
    .popup-body {
      height: calc(100% - 42px);
    }
  }
  &.no-animations {
    .popup-content {
      display: none;
      transition: none;
      left: 0;
    }
    &.open {
      .popup-content {
        display: block;
      }
    }
  }
  &.popup_gray {
    .popup-content {
      background: $bg-gray-color;
    }
  }
  .popup-content {
    position: fixed;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    z-index: 2000;
    opacity: .1;
    background: $bg-color;
    transition: left .3s ease-in, opacity .3s ease-out;
  }
  .popup-title {
    min-height: 42px;
    padding: 19px 40px 16px;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
    .ico_close {
      position: absolute;
      left: 16px;
      top: 18px;
    }
  }
  .popup-shadow {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.54);
    z-index: 1999;
  }
  .popup-body {
    position: relative;
    padding: 24px 16px;
    overflow: auto;
    height: calc(100% - 58px);
  }
}
</style>