import api from '@rosfines/vue-common/common/api/osagoApi';
import financeApi from '@rosfines/vue-common/common/api/financeApi';
import store from '@/store';

export default {
    tracking(eventId, experimentId, cohortId, source, mod) {
      let data = { eventId, source, mod };
      if (cohortId) {
        data.cohortId = cohortId;
      }
      if (experimentId) {
        data.experimentId = experimentId;
      }
      if (store.state.isWeb) {
        if (store.state.externalAuth?.userId) {
          if (!localStorage.getItem("isSentAdId") && (store.state.externalAuth.ymId || store.state.externalAuth.gaId)) {
            financeApi.post(`/analytics/track-guest`, {
              "eventId": "Авторизация по userId",
              "ymCliendId": store.state.externalAuth.ymId,
              "gaClientId": store.state.externalAuth.gaId,
              "experimentId": data.experimentId,
              "cohortId": data.cohortId,
              "source": data.source,
              "mod": store.state.externalAuth.userId,
            }).then(() => {
              localStorage.setItem("isSentAdId", 1);
            });
          }
          return api.post(`/analytics/track`, data, {
            userId: store.state.externalAuth.userId,
            session: store.state.externalAuth.session,
            rand: store.state.externalAuth.rand,
          });
        } else {
          return financeApi.post(`/analytics/track-guest`, {
            "eventId": data.eventId,
            "ymCliendId": store.state.externalAuth.ymId,
            "gaClientId": store.state.externalAuth.gaId,
            "experimentId": data.experimentId,
            "cohortId": data.cohortId,
            "source": data.source,
            "mod": data.mod,
          });
        }
      } else {
        return api.post(`/analytics/track`, data);
      }
    },
}
