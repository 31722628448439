import urlHelper from '@/utils/url.helper';
import trackingApi from '@/services/tracking';

export const statService = {
  channel: 'DefaultApp',

  setStatServiceDataParam($route, amplitudeData = {}, userProperties = {}) {
  },

  init({channel}) {
    this.channel = channel;
  },

  getSource() {
    return localStorage.getItem("source") || "";
  },

  logEvent(eventType, eventProperties = {}) {
    let mod = urlHelper.parseQueryString(location.href).GET.mod || "";
    let eventData = {
      ...eventProperties
    };
    if (!mod && eventProperties.mod) {
      mod = eventProperties.mod;
    }
    statService.setStatServiceDataParam({query: {}}, eventData);

    const amplitudeData = {
      ...eventData,
      mod: mod,
      source: this.getSource(),
    }

    if (process.env.NODE_ENV !== 'production' || localStorage.getItem("debugMode")) {
      console.log(`%c${eventType}`, "color: white; background: #448aff; padding: 2px", amplitudeData);
    }
    eventProperties = this.filterParams(amplitudeData);
    return Promise.all([
      trackingApi.tracking(
        `[Посадочная страхования] ${eventType}`,
        eventProperties.experimentId,
        eventProperties.cohortId,
        (eventProperties.source ? eventProperties.source : ''),
        (eventProperties.mod ? eventProperties.mod : '')
      )
    ]);
  },
  /**
   *  Возвращает объект c теми полями eventProperties, которые находятся в snake_case
   * и длина которых не более 255 символов
   * @param {Object} eventProperties
   */
  filterParams(eventProperties) {
    let
      i, value,
      result = {};
    for (i in eventProperties) {
      value = String(eventProperties[i]);
      if (value.length < 256) {
        result[i] = value;
      } else {
        throw new Error('eventProperties.' + i + ' must be less 256 symbols');
      }
    }

    return result;
  },
}
