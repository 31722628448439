import axios from 'axios';
import Cookies from "js-cookie";
import config from '@/config';

const axiosApi = axios.create({
  baseURL: config.API_BASE_URL_V2,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  validateStatus(status) {
    return status >= 200 && status <= 500;
  },
});

export default {

  post(url, data = {}, params = {}, config = {}) {
    config.params = Object.assign(params, this.getRequestParams());
    return axiosApi.post(url, data, config);
  },

  get(url, params = {}, config = {}) {
    config.params = Object.assign(params, this.getRequestParams());
    return axiosApi.get(url, config);
  },

  getRequestParams() {
    return {
      userId: Cookies.get('requestParams.userId'),
      rand: Cookies.get('requestParams.rand'),
      session: Cookies.get('requestParams.session'),
    }
  },

  isSuccess(response) {
    return response && response.status && response.status === 200 && "data" in response && !response.data.error;
  },

  throwError(response) {
    return response && response.data || {};
  }


}
