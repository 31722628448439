import osagoApi from "@/services/osagoApi"
import {getV3Token, getV2Token} from '@/utils/recaptcha';
import { frontDate2BackDate } from '@/utils/dateConvert';
import sleep from '@/utils/sleep';

/////////////////////////////////
let resolveWaitLoadingCarsList;
let rejectWaitLoadingCarsList;
let listLoad;
function reInitListLoad () {
  rejectWaitLoadingCarsList && rejectWaitLoadingCarsList();
  listLoad = new Promise((resolve, reject) => {
    resolveWaitLoadingCarsList = resolve;
    rejectWaitLoadingCarsList = reject;
  });
}
reInitListLoad();

/////////////////////////////////

let resolveWaitLoadingCarData;
let rejectWaitLoadingCarData;
let formLoad;
function reInitFormLoad () {
  // rejectWaitLoadingCarData && rejectWaitLoadingCarData();
  formLoad = new Promise((resolve, reject) => {
    resolveWaitLoadingCarData = resolve;
    rejectWaitLoadingCarData = reject;
  });
}
reInitFormLoad();
/////////////////////////////////

const osago = {
  namespaced: true,
  state() {
    return {
      carsListLoaded: false,
      carsList: [],

      activeCarId: 0,
      activeCarData: {},

      activePolicyCarId: 0,
      activePolicyData: {},

      companies:[],
      currentlyAddedPolicies: [],
    }
  },
  getters: {
    getAllCompanies(state) {
      return state.companies.filter(item => item.isActive);
    },
    getICById: (state) => (id) => {
      return state.companies.find(item => item.id === id)
    },
    formCompleteness: (state) => state.activeCarData.formCompleteness,
  },
  mutations: {
    saveCarsList(state, list) {
      if (Array.isArray(list)) {
        state.carsList = list;
        state.carsListLoaded = true;
      }
    },
    appendCar2List(state, car) {
      state.carsList.push({
        id: car.car.id,
        vehiclePlate: car.car.vehiclePlate,
        brand: car.car.carModification.brand,
        model: car.car.carModification.model,
        modification: car.car.carModification.id,
        status: 1,
      });
    },
    _chooseCar(state, carId) {
      state.activeCarId = carId;
    },
    saveActiveCarData(state, data) {
      state.activeCarData = data;
    },
    saveActivePolicyData(state, {carId, data}) {
      state.activePolicyCarId = carId;
      state.activePolicyData = data;
    },
    setCompaines(state, list) {
      state.companies = list;
    },
    pushAddedPolicy(state, data) {
      state.currentlyAddedPolicies.push(data);
    }
  },
  actions: {
    async waitLoadingCarsList() {
      return listLoad;
    },
    async loadCarsList({commit}) {
      const response = await osagoApi.get("/policy-form/cars")
      if (osagoApi.isSuccess(response)) {
        commit("saveCarsList", response.data.items);
        resolveWaitLoadingCarsList()
      } else {
        rejectWaitLoadingCarsList();
        throw new Error("Cars list loading failed")
      }
      return response;
    },
    async addCar({commit, dispatch}, {
      vehiclePlate,
      isLeftHandDrive = undefined,
      restore = false,
      addToFines = false
    }) {
      let response = {};
      let interruptAdding = false;
      const v3Token = await getV3Token().catch((e) => {
        console.error(e);
        throw e;
      });
      response = await osagoApi.post("/policy-form", {
        vehiclePlate,
        isLeftHandDrive,
        isAddToFines: addToFines,
      }, undefined,{
        headers: {
          "recaptcha-token-v3": v3Token,
        }
      }).catch( async (e) => {
        if (e && e.response && e.response.status === 409) {
          interruptAdding = true;
          if (restore) {
            await dispatch("restoreCar", vehiclePlate);
          } else {
            //show modal
            await new Promise((resolve, reject) => {
              commit("createRestoreRequest", {
                vehiclePlate,
                resolve,
                reject
              });
            }).then(async () => {
              await dispatch("restoreCar");
            }).catch(() => {}).finally(() => {
              commit("createRestoreRequest");
            })
          }
        } else {
          console.error(e);
          throw e;
        }
      });
      if (interruptAdding) {
        return true;
      }
      if (response && !osagoApi.isSuccess(response) && response.data && response.data.data && response.data.data.invalidTokenV3) {
        const v2Token = await getV2Token().catch((e) => {
          console.error(e);
          throw e;
        });
        response = await osagoApi.post("/policy-form", {
          vehiclePlate,
          isLeftHandDrive,
          isAddToFines: addToFines,
        }, undefined, {
          headers: {
            "recaptcha-token-v3": v3Token,
            "recaptcha-token-v2": v2Token,
          }
        }).catch((e) => {
          console.error(e);
          throw e;
        });
      }

      if (osagoApi.isSuccess(response)) {
        commit("appendCar2List", response.data);
        commit("_chooseCar", response.data.car.id);
        await dispatch("loadActiveCarData");
      } else {
        console.error(response);
        throw osagoApi.throwError(response);
      }
      return response;
    },
    async waitLoadingCarData() {
      return formLoad;
    },
    async chooseCar({state, dispatch, commit}, carId) {
      if (state.activeCarId) {
        reInitFormLoad();
      }
      commit("_chooseCar", carId);
      await dispatch("loadActiveCarData");
    },
    async loadActiveCarData({state, commit, dispatch}) {
      if (state.activeCarId) {
        const carState = await osagoApi.get(`/policy-form/by-car/${state.activeCarId}/state`).catch(async (e) => {
          throw new Error('loading car state failed');
        });
        if (osagoApi.isSuccess(carState)) {
          commit("saveActiveCarData", {
            formCompleteness: carState.data.formCompleteness,
          });
          await dispatch("getLastPolicy");
          resolveWaitLoadingCarData();
        }
      }
    },

    async getPurchaseStatus({ state }) {
      if (state.activeCarId) {
        const response = await osagoApi.get(`/partner/car/${state.activeCarId}/purchase-status`).catch(async () => {
          return false;
        });
        if (osagoApi.isSuccess(response)) {
          return response.data;
        } else {
          return false;
        }
      }
    },
    async getLastOffer({ state }) {
      if (state.activeCarId) {
        const response = await osagoApi.get(`/partner/car/${state.activeCarId}/last-actual-unpaid-offer`).catch(async () => {
          return false;
        });
        if (osagoApi.isSuccess(response)) {
          return response.data;
        } else {
          return false;
        }
      }
    },
    async getLastCalcs({ state }) {
      if (state.activeCarId) {
        const response = await osagoApi.get(`/partner/car/${state.activeCarId}/last-calculations`).catch(async () => {
          return false;
        });
        if (osagoApi.isSuccess(response)) {
          return response.data;
        } else {
          return false;
        }
      }
    },
    async getLastPolicy({ state, commit }) {
      const carId = state.activeCarId
      if (carId) {
        const response = await osagoApi.get(`/policy/${carId}/last-policy`).catch(async () => {
          return false;
        });
        if (osagoApi.isSuccess(response)) {
          commit("saveActivePolicyData", {
            carId,
            data: response.data,
          });
          return response.data;
        } else {
          return false;
        }
      }
    },
    async invalidatePolicy({state}) {
      const response = await osagoApi.post(`/rsa/invalidate-policy`, {
        "policyNumber": `${state.activePolicyData.series} ${state.activePolicyData.number}`
      }).catch(async () => {
        return false;
      });
      if (osagoApi.isSuccess(response)) {
        return response.data;
      } else {
        return false;
      }
    },
    async addPolicy({commit}, formData) {
      formData.expirationDate = frontDate2BackDate(formData.expirationDate);
      const response = await osagoApi.post("/rsa/add-policy", formData).catch((error) => {
        throw error;
      });
      if (osagoApi.isSuccess(response)) {
        commit('pushAddedPolicy', `${formData.vehiclePlate} ${formData.vin}`)
        return true;
      } else {
        console.error(response);
        throw response;
      }
    },
    async loadCompaines({commit}) {
      const response = await osagoApi.get("/partner/insurance-companies").catch((e) => {
        console.error(e);
        throw "companies loading failed. code #1";
      });
      if (osagoApi.isSuccess(response)) {
        const list = response.data;
        commit("setCompaines", list);
      } else {
        throw "companies loading failed. code #2";
      }
    }
  }
}

export default osago;