<template>
  <loading
    :active="globalLoading"
  />
  <div
    v-show="!globalLoading"
    class="content"
  >
    <router-view />
  </div>

  <popup
    id="modal-recaptcha"
    ref="modal-recaptcha"
    :short-view="true"
    :no-animations="true"
    title="Рекапча"
  >
    <div id="recaptcha-wrapper" />
  </popup>
</template>
<script>

import Loading from "vue-loading-overlay";
import Popup from '@/components/blocks/Popup';
import { mapActions, mapMutations, mapState } from 'vuex';
import { statService } from '@/utils/statService';
import urlHelper from '@/utils/url.helper';
import finesApi from '@/services/finesApi';

const GET = urlHelper.parseQueryString(location.href).GET;

if (GET.web_path) {
  const newGET = Object.keys(GET).map((key) => {
    if (key === 'web_path') {
      return undefined;
    } else if (key === 'web_query') {
      return decodeURIComponent(GET.web_query);
    } else {
      return `${key}=${encodeURIComponent(GET[key])}`;
    }
  }).filter(item => !!item);
  location.replace(`${decodeURIComponent(GET.web_path)}?${newGET.join("&")}`);
}

if (GET.userId && GET.rand && GET.session) {
  const params = {response: GET};
  finesApi.updateRequestParams(params, false);
}
if (GET.channel) {
  localStorage.setItem("channel", GET.channel);
  statService.init({
    channel: GET.channel
  });
}
if (GET.source) {
  localStorage.setItem("source", GET.source);
}
// отключено создание юзера
// store.dispatch("auth", localStorage.getItem("source"));

export default {
  name: 'App',
  components: {
    Loading,
    Popup,
  },
  computed: {
    ...mapState({
      globalLoading: (state) => state.globalLoading,
      isWeb: (state) => state.isWeb,
    }),
  },
  async beforeMount() {
    await this.getCarboxAuthHashForKaskoWhiteLabel();
    if (!this.isWeb) {
      await this.loadCarsList();
      await this.loadCompaines();
    }
  },
  async mounted() {
    this.loaded();
    if (!this.isWeb) {
      await this.waitLoadingCarsList();
    }
    statService.logEvent('[Ситуация] Приложение загружено');
    this.setGlobalLoading(false);
    window.showRecaptchaModal = () => {
      this.$refs["modal-recaptcha"].show();
    }
    window.hideRecaptchaModal = () => {
      this.$refs["modal-recaptcha"].hide();
    }
  },
  methods: {
    ...mapActions({
      loaded: 'communications/sendLoadedEvent',
      waitLoadingCarsList: "osago/waitLoadingCarsList",
      loadCarsList: "osago/loadCarsList",
      loadCompaines: "osago/loadCompaines",
      getCarboxAuthHashForKaskoWhiteLabel: "kaskoWhiteLabel/getAuthHash",
    }),
    ...mapMutations({
      setGlobalLoading: 'setGlobalLoading',
    })
  },
}
</script>
